<template>
  <v-theme-provider dark>
    <base-section
      class="accent"
      space="56"
    >
      <v-container>
        <base-icon
        class="mb-8"
         >
        </base-icon>
        <base-section-heading
         title="급식단체용 세척제"
         :inset="inset"
        >
        </base-section-heading>
      <v-card
          max-width="1080"
          class="mx-auto"
        >
          <v-list
          class="accent"
          three-line
          >
            <template v-for="(info, index) in items">
              <v-divider
                v-if="info.divider"
                :key="index"
              ></v-divider>

              <v-list-item
                v-else
                :key="info.title"
              >
                <v-list-item-avatar>
                  <v-img :src="info.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="info.title"></v-list-item-title>
                  <v-card-text v-html="info.subtitle"> </v-card-text>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    provide: {
      heading: { align: 'center' },
      theme: { isDark: true },
    },

    data: () => ({
      items: [
        { header: 'Today' },
        {
          avatar: '',
          title: '1. 엑셀콩크 (1종세제)',
          subtitle: '1) 사용처 : 야채, 과일은 물론 주방기구,조리기구 등에 사용하시는 세척제 입니다.<br> 2) 사용방법 <br> &nbsp;&nbsp;- 물 1L에 엑셀콩크 2g 희석사용 <br> &nbsp;&nbsp;- 일반적인 주방용 세척제(트리오,퐁퐁 등)의 사용방법과 동일합니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '2. GP클리너 (다목적 세척제)',
          subtitle: '1) 사용처 : 주방기구, 바닥, 벽 등의 기름때 및 찌든때 제거용 세척제 입니다.<br> 2) 사용방법 <br> &nbsp;&nbsp;- 사용농도를 20%(1:5) ~ 원액까지 오염정도에 따라 희석하여 사용 할 수 있습니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '3. 오븐클리너 (강력 기름때 제거제)',
          subtitle: '1) 사용처 : 오븐, 그릴, 닥트 등의 찌든 기름때 및 타서 눌러붙은 기름때 제거용 세척제 입니다.<br> 2) 사용방법 <br> &nbsp;&nbsp;- 오염 정도에 따라 원액 및 희석액 사용이 가능하며, 온수에 희석하시면 효과가 상승됩니다.<br> &nbsp;&nbsp;- 오븐/그릴 : 수세미 등을 사용하여, 원액을 오븐에 흐를 정도로 충분히 분무 후 닦아내면 됩니다. <br> &nbsp;&nbsp;이 경우 오븐을 뜨겁게 한 후 세제를 사용하시면 더욱 효과적입니다. <br> &nbsp;&nbsp;그릴에 오염이 심한 경우 용액에 수분간 침지한 후 물로 뿌려주거나 깨끗한 물에 침지하여 문질러 닦아 주면 됩니다.<br> &nbsp;&nbsp;- 닥트 : 분무기를 사용하여 용액을 뿌려준 후 깨끗한 물을 뿌려 주면 됩니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '4. 디스탄 (은기물 변색 제거제)',
          subtitle: '1) 사용처 : 대기중의 가스나 강한 성분의 식품에 닿아 표면이 검게 변한 은기물의 변색 제거제 입니다.<br> 2) 사용방법 <br> &nbsp;&nbsp;- 원액에 1초간 담근 후 즉시 주방용 세척제인 엑셀콩크로 다시 씻어주기만 하면 됩니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '5. 자동식기세척기용 세제',
          subtitle: '1) 산이솔 : 자동식기세척기용 분말세제<br>2) 산히트 : 자동식기세척기용 약생세제<br>3) 린즈디 : 자동식기세척기용 건조촉진제<br>4) 클리너#7 : 자동식기세척기용 스케일 제거제',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '6. 드레인클리너 (배수 파이프 세관제)',
          subtitle: '1) 사용처 : 주방, 씽크대, 욕실, 세면대, 화장실의 막힌 배수파이프를 뚫는데 사용<br> 2) 사용방법 <br> &nbsp;&nbsp;- 원액 0.5L를 붓고 10분후 물을 부으면 됩니다.&nbsp;&nbsp;주방의 배수구는 기름때와 찌꺼기로 쉽게 막히게 되므로 일과가 끝날때 마다 본세제 원액을 소량씩 투여하여 막힘을 사전에 예방하실 수 있습니다.',
        },
      ],
      drawer: null,
      inset: false,
    }),
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },
  }
</script>

<style scoped>
.v-list-item__title {
    font-size: 18px;
    color: coral !important;
}
</style>
